import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import Container from "../components/Container"

const StyledContainer = styled(Container)`
  margin: 50px auto;
  padding: 30px 20px;
  
  color: #000000;
  background-color: #ffffff;
  
  box-shadow: 0px 2px 5px 3px rgba(0,0,0,0.75);
`

const Resume = () => {
  return (
    <Layout>
      <StyledContainer maxWidth="830px">
        awdaw
      </StyledContainer>
    </Layout>
  )
}

export default Resume
